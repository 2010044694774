/* Visitor.module.css */

/* General Container Styling */
.container {
    background-color: #f8f9fa;
    padding: 40px;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

/* Form Header */
.header {
    font-size: 24px;
    font-weight: 600;
    color: #343a40;
    margin-bottom: 20px;
}

/* Label Styling */
label {
    font-size: 14px;
    font-weight: 500;
    color: #495057;
}

/* Input and Textarea Styling */
input[type="text"],
input[type="email"],
input[type="number"],
input[type="date"],
input[type="file"],
select,
textarea {
    background-color: #fff;
    border: 1px solid #ced4da;
    padding: 10px;
    border-radius: 4px;
    font-size: 14px;
    width: 100%;
    margin-bottom: 20px;
    box-shadow: none;
    transition: border-color 0.3s;
}

/* Hover and Focus Effects */
input[type="text"]:focus,
input[type="email"]:focus,
input[type="number"]:focus,
input[type="date"]:focus,
input[type="file"]:focus,
select:focus,
textarea:focus {
    border-color: #80bdff;
    outline: none;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.25);
}

/* Button Container Styling */
.formButtons {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    margin-top: 20px;
}

/* Button Styling */
.button {
    padding: 10px 20px;
    font-size: 14px;
    font-weight: 500;
    border-radius: 4px;
    cursor: pointer;
    border: none;
    transition: background-color 0.3s, transform 0.2s, box-shadow 0.2s;
}

/* Submit Button Styling */
.submitButton {
    background-color: #007bff;
    color: white;
}

.submitButton:hover {
    background-color: #0056b3;
    transform: translateY(-2px);
    box-shadow: 0 2px 10px rgba(0, 123, 255, 0.2);
}

/* Cancel Button Styling */
.cancelButton {
    background-color: #6c757d;
    color: white;
}

.cancelButton:hover {
    background-color: #5a6268;
    transform: translateY(-2px);
    box-shadow: 0 2px 10px rgba(108, 117, 125, 0.2);
}

/* Responsive Styling */
@media (max-width: 768px) {
    .container {
        padding: 20px;
    }
}
