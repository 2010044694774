.visitor-details-container {
  background: linear-gradient(135deg, #ece9e6 0%, #ffffff 100%);
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.visitor-header {
  background-color: #007bff;
  color: white;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.visitor-card-body {
  background-color: #ffffff;
  padding: 2rem;
}

.section-title {
  font-size: 1.5rem;
  font-weight: bold;
  color: #007bff;
  margin-bottom: 1rem;
  text-align: center;
}

.personal-details-section,
.professional-details-section,
.previous-jobs-section,
.education-section,
.skills-section,
.references-section,
.questions-section {
  border-bottom: 1px solid #eaeaea;
  padding-bottom: 15px;
  margin-bottom: 30px;
}

.list-group-item {
  font-size: 1.1rem;
  border: none;
  padding: 0.75rem 1.25rem;
  background-color: #f8f9fa;
  margin-bottom: 5px;
}

.list-group-item strong {
  color: #343a40;
}

a {
  text-decoration: none;
  color: #007bff;
}

a:hover {
  text-decoration: underline;
}

.visitor-details-container .btn {
  padding: 0.75rem 2rem;
  font-size: 1.1rem;
}

@media (max-width: 576px) {
  .visitor-details-container {
    padding: 10px;
  }
}
