/* Global Styling */
body {
    background: linear-gradient(135deg, #e0eafc, #cfdef3);
    min-height: 100vh;
    font-family: 'Arial', sans-serif;
    margin: 0;
    padding: 0;
}

/* Header Styling */
.admin-header {
    background: linear-gradient(145deg, rgba(58, 123, 213, 0.8), rgba(38, 120, 213, 0.8));
    padding: 15px 30px;
    border-radius: 12px;
    box-shadow: 0 8px 32px rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(12px);
    border: 1px solid rgba(255, 255, 255, 0.18);
    color: #ffffff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}

.full-screen-form {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    padding: 20px;
  }
  
.admin-title {
    font-size: 28px;
    font-weight: bold;
    margin: 0;
    color: #f8f9fa;
}

.search-container {
    width: 300px;
    position: relative;
}

.search-bar {
    width: 100%;
    padding: 10px 20px;
    border-radius: 30px;
    border: none;
    outline: none;
    background: rgba(255, 255, 255, 0.25) !important; /* Override the white background */;
    color: #ffffff;
    font-size: 16px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(5px);
    transition: background 0.3s;
}

.search-bar::placeholder {
    color: rgba(255, 255, 255, 0.6);
}

.search-bar:focus {
    background: rgba(255, 255, 255, 0.35);
}

/* Sidebar and Content Flexbox */
.dashboard {
    display: flex;
    align-items: flex-start;
    padding: 20px;
    flex-wrap: wrap;
    min-height: calc(100vh - 70px);
}

.sidebar {
    background: rgba(248, 249, 250, 0.8);
    padding: 20px;
    border-radius: 12px;
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(8px);
    border: 1px solid rgba(255, 255, 255, 0.18);
    flex: 1;
    max-width: 300px;
    margin-bottom: 20px;
}

.content {
    background: rgba(255, 255, 255, 0.2);
    padding: 30px;
    border-radius: 15px;
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(8px);
    flex: 3;
    margin-left: 20px;
    width: 100%;
}

/* Sidebar Navigation */
.nav-link {
    font-size: 18px;
    color: #007bff;
    margin-bottom: 15px;
    padding: 10px 15px;
    border-radius: 10px;
    transition: all 0.3s ease;
    background: rgba(255, 255, 255, 0.1);
}

.nav-link:hover,
.nav-link.active {
    background: rgba(255, 255, 255, 0.3);
    color: #0056b3;
    font-weight: bold;
}

/* Table Styles */
.table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
}

.table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
}

.table th, .table td {
    padding: 15px;
    text-align: center;
    border: 1px solid #ddd;
    font-size: 14px;
    word-wrap: break-word; 
}
.btn-warning {
    background-color: #ffc107;
    border-color: #ffc107;
}

.btn-warning:hover {
    background-color: #e0a800;
    border-color: #d39e00;
}



@media (max-width: 768px) {
    .btn-view, .btn-approve {
        font-size: 12px;
        padding: 5px 10px;
    }

    td {
        white-space: normal; 
        overflow-wrap: break-word; 
    }

    /* Ensure container takes full width on small screens */
    .table-responsive {
        width: 100%;
        padding: 10px;
    }

    .table th, .table td {
        padding: 10px;
        font-size: 12px;
    }

    .container {
        padding: 10px;
    }

    .admin-title {
        font-size: 16px;
    }

    /* Adjust sidebar and content alignment */
    .sidebar {
        max-width: 100%; 
        margin-bottom: 20px;
    }

    .content {
        width: 100%; 
        margin-left: 0;
    }
}
/* Mobile Header Adjustments */
@media (max-width: 768px) {
    .admin-header {
        flex-direction: column;
        align-items: flex-start;
    }

    .admin-title {
        font-size: 22px;
        margin-bottom: 10px;
        width: 100%;
    }

    .search-container {
        width: 100%;
        margin-top: 10px;
    }

    .search-bar {
        font-size: 14px;
        padding: 8px;
    }
}

@media (max-width: 576px) {
    .admin-title {
        font-size: 20px;
        width: 100%;
    }

    .search-container {
        width: 100%;
        margin-top: 5px;
    }

    .search-bar {
        font-size: 12px;
        padding: 6px;
    }
}

/* Change layout for screens below 580px */
@media (max-width: 580px) {
    .dashboard {
        flex-direction: column; 
    }

    .sidebar {
        order: -1; 
        max-width: 100%; 
        margin-bottom: 20px;
    }

    .content {
        order: 1; 
        margin-left: 0;
        width: 100%; 
    }

    .visitor-cards {
        order: 1;
    }
}

/* For extra small devices like phones */
@media (max-width: 576px) {
    .dashboard {
        flex-direction: column; 
    }

    .sidebar {
        order: -1; 
        max-width: 100%; 
        margin-bottom: 20px;
    }

    .content {
        order: 1;
        margin-left: 0; 
        width: 100%; 
    }

    .btn-view, .btn-approve {
        font-size: 10px;
        padding: 4px 8px;
    }

    td {
        font-size: 10px;
        padding: 5px;
    }

    .table {
        font-size: 10px;
    }
}

/* Visitor card styles for mobile view */
.visitor-cards {
    display: grid;
    gap: 15px;
}

.visitor-card {
    background: #f8f9fa;
    padding: 15px;
    border-radius: 10px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    border: 1px solid #ddd;
}

.visitor-card h5 {
    margin-top: 0;
    font-size: 18px;
}

.visitor-card p {
    margin-bottom: 5px;
    font-size: 14px;
}

.visitor-actions {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}


@media (min-width: 768px) {
    .visitor-cards {
        display: none; 
    }
}


@media (max-width: 767px) {
    .table-responsive {
        display: none;
    }
}
