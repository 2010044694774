/* Custom styling for Download Last Degree Section */
.download-other-docs-section {
    max-width: 600px; /* Limit maximum width for larger screens */
    margin: auto; /* Center the section */
    padding: 20px; /* Add padding for better spacing */
    background-color: #f8f9fa; /* Light background for contrast */
    border-radius: 15px; /* Rounded corners for the section */
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1); /* Subtle shadow effect */
}

.custom-download-btn {
    background-color: #ff6f61 !important; /* Updated color for the button (soft coral) */
    color: #fff !important; /* White text */
    font-size: 1.2rem; /* Increase font size */
    padding: 15px 25px; /* Add more horizontal padding for a larger button */
    border-radius: 30px; /* More rounded corners */
    transition: background-color 0.3s ease, transform 0.2s ease, box-shadow 0.2s ease; /* Smooth transitions for hover effect */
    border: none; /* Remove default border */
}

.custom-download-btn:hover {
    background-color: #ff4c40 !important; /* Darker shade on hover */
    transform: translateY(-2px); /* Slight lift effect on hover */
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2); /* Shadow effect on hover */
}

@media (max-width: 768px) {
    .download-other-docs-section h4 {
        font-size: 1.5rem; /* Adjust heading size for smaller screens */
    }

    .download-other-docs-section .custom-download-btn {
        font-size: 1rem; /* Adjust button font size for smaller screens */
    }
}
